// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_3160 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 550px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 500px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 500px);
    background: url(/i/design/gradient-bottom.png) repeat-x top left;
    bottom: -10px;
    left: 0;
  }

  img {
    top: 0px !important;

      @-webkit-keyframes zoom {
        from {
          -webkit-transform: scale(1);
          transform: scale(1)
        }

        to {
          -webkit-transform: scale(1.1);
          transform: scale(1.1)
        }
      }

      @-moz-keyframes zoom {
        from {
          -moz-transform: scale(1);
          transform: scale(1)
        }

        to {
          -moz-transform: scale(1.1);
          transform: scale(1.1)
        }
      }

      @keyframes zoom {
        from {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          transform: scale(1)
        }

        to {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          transform: scale(1.1)
        }
      }

      -webkit-animation:zoom 30s ease forwards infinite;
      -moz-animation:zoom 30s ease forwards infinite;
      animation:zoom 30s ease forwards infinite
    }
  }



// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  right: 100px;
  top: 135px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-style: italic;

  &:before {
    content: "";
    position: absolute;
    background: url(/i/design/strapline-border.png);
    height: 16px;
    width: 483px;
    left: 50%;
    transform: translate(-50%);
    top: -30px;
  }

  @media only screen and (max-width: 1150px) {
		right: 35px;
	}

  @media only screen and (max-width: 1090px) {
    right: 0;
    left: 0;
    top: 350px;
		}

    @media only screen and (max-width: 600px) {
      top: 500px;
 }  

    @media only screen and (max-width: 550px) {
      padding: 0 20px;
      font-size: 18px;
 }  

 @media only screen and (max-width: 360px) {
  font-size: 15px;
} 
}